export default {
  service: {
    setLanguage: '設定語言'
  },
  setting: '設定',
  send: '發送',
  copy: '複製',
  hint: '提示',
  copySuccess: '複製成功',
  copyFail: '複製失敗',
  connecting: '連接中',
  accountIsInexistence: '賬號信息不存在，請聯繫管理員',
  systemError: '系統錯誤，請稍後再試',
  authenticationFailed: '用戶驗證失敗，請重新登錄',
  translate: '翻譯',
  withdraw: '撤回',
  edit: '編輯',
  edited: '已編輯',
  translating: '翻譯中',
  translateFail: '翻譯失敗，請重試',
  result: '結果',
  translationNotResult: '翻譯沒有結果，請檢查文本是否輸入正確',
  you: '您',
  otherSide: '對方',
  withdrawnAMessage: '撤回了一條消息',
  serviceLanguage: '服務語言',
  selectServiceLanguage: '請選擇需要的語言類型',
  automaticTranslation: '自動翻譯',
  automaticTranslationTip: '消息自動翻譯為所選語言',
  automaticTranslationTip2: '若“自動翻譯”關閉了，長按消息也可調起翻譯功能',
  connectionSucceeded: '連接成功',
  connectionSucceededTip: '服務器連接成功，系統已為您開啟新消息聲音提醒/斷線聲音提醒（請確認您的設備已打開音頻音量）',
  disconnectionReminder: '斷線提醒',
  disconnectionReminderTip:
    '您當前已掉線，請檢查您的網絡是否正常，若網絡正常請嘗試刷新瀏覽器，若依然連接失敗，請聯繫平台。',
  allEmoji: '所有表情',
  photo: '照片',
  camera: '相機',
  voice: '語音'
}
