export default {
  service: {
    setLanguage: '设置语言'
  },
  setting: '设置',
  send: '发送',
  copy: '复制',
  hint: '提示',
  copySuccess: '复制成功',
  copyFail: '复制失败',
  connecting: '连接中',
  accountIsInexistence: '账号信息不存在，请联系管理员',
  systemError: '系统错误，请稍后再试',
  authenticationFailed: '登录失效，请重新登录',
  translate: '翻译',
  withdraw: '撤回',
  edit: '编辑',
  edited: '已编辑',
  translating: '翻译中',
  translateFail: '翻译失败，请重试',
  result: '结果',
  translationNotResult: '翻译没有结果，请检查文本是否输入正确',
  you: '您',
  otherSide: '对方',
  withdrawnAMessage: '撤回了一条消息',
  serviceLanguage: '服务语言',
  selectServiceLanguage: '请选择需要的语言类型',
  automaticTranslation: '自动翻译',
  automaticTranslationTip: '消息自动翻译为所选语言',
  automaticTranslationTip2: '若“自动翻译”关闭了，长按消息也可调起翻译功能',
  connectionSucceeded: '连接成功',
  connectionSucceededTip: '服务器连接成功，系统已为您开启新消息声音提醒/断线声音提醒（请确认您的设备已打开音频音量）',
  disconnectionReminder: '断线提醒',
  disconnectionReminderTip:
    '您当前已掉线，请检查您的网络是否正常，若网络正常请尝试刷新浏览器，若依然连接失败，请联系平台。',
  allEmoji: '所有表情',
  photo: '照片',
  camera: '相机',
  voice: '语音'
}
